<template>
  <div>
    <v-row class="ma-3">
      <v-col cols="12" md="6" lg="6">
        <BaseFilter
          v-model="filters.id_campanha"
          prepend-inner-icon="mdi-chart-line"
          clearable
          label="Campanha"
          item-text="nome_campanha"
          item-value="id_campanha"
          service="campanhaService"
          :prependItem="true"
          :hasCode="true"
        />
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <BaseFilter
          :disabled="!filters.id_campanha"
          v-model="filters.id_empresa"
          :multiple="true"
          :isCompany="true"
          prepend-inner-icon="mdi-domain"
          clearable
          label="Empresa"
          item-text="apelido"
          item-value="id_empresa"
          service="campanhaService.empresa"
          :filters="{
            id_campanha: filters.id_campanha
          }"
          :prependItem="true"
        />
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <DatePickerMonth
          style="margin-top: -6px"
          @date="assignDateFilter($event)"
        />
      </v-col>
    </v-row>
    <v-divider class="ma-4"></v-divider>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :actualPage="1"
      :lastPage="nLastPage"
      @pageChange="fetchProcedures($event)"
      item-key="id_processo"
      show-select
      v-model="processosSelecionados"
      sort-by="ordem"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Processos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.data_proc`]="{ item }">
        {{ item.data_proc | BrazilianStandardDateAndTime }}
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openModal(item)">
              <v-icon color="blue-grey" v-bind="attrs" v-on="on">
                mdi-file-cog
              </v-icon>
            </v-btn>
          </template>
          <span>Ver logs</span>
        </v-tooltip>
      </template>
    </BaseTable>
    <v-divider class="ma-4"></v-divider>
    <div class="d-flex justify-end">
      <v-btn text @click="sendProcessa()">
        <v-icon class="mr-1">mdi-cog</v-icon>Processar
      </v-btn>
      <v-btn class="ml-4" text @click="sendProcessaApagar()">
        <v-icon class="mr-1">mdi-trash-can</v-icon>Apagar Dados
      </v-btn>
    </div>

    <ModalLog :process="process" :dialog="dialog" @close="dialog = false" />
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import statusAtivo from "@/mixins/statusAtivo";
import BaseTable from "@/components/shared/NewBaseTable";
import comissao from "@/services/http/comissaoService";
import ModalLog from "./ModalLog.vue";
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListaProcessos",

  mixins: [dateMixin, statusAtivo],

  components: {
    BaseTable,
    ModalLog,
    DatePickerMonth,
    BaseFilter
  },

  data() {
    return {
      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Data de Processamento", value: "data_proc" },
        { text: "Logs", value: "actions" },
        { text: "Status", value: "ativo" }
      ],
      info: [],
      apaga: "N",
      filters: {},
      process: {},
      processosSelecionados: [],
      logs: [],
      dialog: false,
      nLastPage: 0,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      getCampanhas: "campanha/getCampanhas"
    })
  },

  methods: {
    ...mapActions({
      fetchCampanhas: "campanha/fetchCampanhas"
    }),
    async getCampanhaByEvent() {
      this.fetchCampanhas({
        id_tipo_evento: "M",
        id_status_camp: [1, 21, 22],
        per_page: -1
      });
    },
    async fetchProcedures() {
      this.loading = true;
      let { data } = await comissao()
        .procedures()
        .processos()
        .show({
          id_tipo_evento: 2
        });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    },

    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
    },

    openModal(item) {
      this.process = Object.assign({}, item);
      this.dialog = true;
    },

    sendProcessa() {
      const promise = this.processosSelecionados.map(item => {
        const promises = this.filters.id_empresa.map(value => {
          this.form = {
            id_processo: item.id_processo,
            id_tipo_evento: "M",
            apaga: "N",
            id_campanha: this.filters.id_campanha,
            id_empresa: value,
            ano_ref: this.filters.ano_ref,
            mes_ref: this.filters.mes_ref
          };
          comissao()
            .procedures()
            .processa()
            .store(this.form, {
              notification: true,
              message: "Processo executado com sucesso!"
            });
        });
        return promises;
      });
      Promise.all(promise)
        .then(() => (this.form = {}))
        .catch(error => {
          console.error(error);
          this.$notify({
            type: "error",
            text: "Erro ao executar procedure"
          });
        });
    },
    sendProcessaApagar() {
      const promise = this.processosSelecionados.map(item => {
        const promises = this.filter.id_empresa.map(value => {
          this.form = {
            id_processo: item.id_processo,
            id_tipo_evento: "M",
            apaga: "S",
            id_campanha: this.filters.id_campanha,
            id_empresa: value,
            ano_ref: this.filter.ano_ref,
            mes_ref: this.filter.mes_ref
          };
          comissao()
            .procedures()
            .processa()
            .store(this.form, {
              notification: true,
              message: "Processo executado com sucesso!"
            });
        });
        return promises;
      });
      Promise.all(promise)
        .then(() => (this.form = {}))
        .catch(error => {
          console.error(error);
          this.$notify({
            type: "error",
            text: "Erro ao executar procedure"
          });
        });
    }
  },

  mounted() {
    this.fetchProcedures();
    this.getCampanhaByEvent();
  }
};
</script>

<style></style>
