<template>
  <v-container class="container-card">
    <v-card>
      <ProcessamentoCampanha />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ProcessamentoCampanha from "@/components/campanha/processamento/ProcessamentoCampanha";
export default {
  name: "ConsultaPermissoes",

  components: {
    ProcessamentoCampanha
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Processamento Campanha");
  }
};
</script>
